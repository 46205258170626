import axios from "axios";
const api = (url = null, token = null) => {
  let config = {};
  if (url) {
    config = {
      baseURL: url,
      headers: {
        Authorization: token && `Bearer ${token}`,
      },
    };
  }
  let instance = axios.create(config);
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (
        error.response &&
        (error.response?.status === 401 ||
          error.response?.data?.statusCode === 401 ||
          error.response?.data?.message.toLowerCase().indexOf("token") >= 0)
      ) {
        localStorage.clear();
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

export default api;
