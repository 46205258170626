import { CITYLISTSUCCESS, CITYLISTFAILURE } from "../../actions/types";

const instrumentData = {
  cityList: {},
};

const preloginReducer = (state = instrumentData, action) => {
  switch (action.type) {
    case CITYLISTSUCCESS:
      return {
        ...state,
        instrumentData: { ...state.instrumentData, ...action.payLoad },
      };

    case CITYLISTFAILURE:
      return {
        ...state,
        instrumentData: { ...state.instrumentData, ...action.payLoad },
      };

    default:
      return state;
  }
};

export default preloginReducer;
