import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";

import "swiper/swiper-bundle.css";
import "./assets/styles/global.scss";
import "./assets/styles/index.scss";
import theme from "./muiTheme";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
