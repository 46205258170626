import aesjs from "aes-js";
import { storageFactory } from "storage-factory";
import api from "./axios";
import { EncryptData } from "../utils/encryptedPayload";
import { ADDCALCULATIONDATASUCCESS } from "../Redux/actions/types";

const paddSpaces = (password) => {
  while (password.length < 16) {
    password = decodeURIComponent(process.env.REACT_APP_PADDSPACES) + password;
  }
  return password;
};

export const encryptPassword = (password) => {
  if (password && password.length > 0) {
    const key = JSON.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = JSON.parse(process.env.REACT_APP_ENCRYPTION_IV);
    const text = password.length < 16 ? paddSpaces(password) : password; // if password < 16 Bytes then paddSpaces
    const textBytes = aesjs.utils.utf8.toBytes(text);
    const aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
    const encryptedBytes = aesCbc.encrypt(textBytes);
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  } else {
    return "";
  }
};

export const isLogin = () => {
  let token = localStorage.getItem("loginToken");
  let checkLogin = false;
  if (token !== undefined && token !== null) {
    checkLogin = true;
  }
  return checkLogin;
};

const baseURL = process.env.REACT_APP_API_DOMAIN;
export const logOut = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview
      .callHandler("logout")
      .then((isSuccessful) => {
        console.log("logout");
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    const token = localStorage.getItem("loginToken");

    api(`${baseURL}/api-mdm/auth/logout`, token)
      .get()
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.error(err);
        localStorage.clear();
        window.location.href = "/";
      });
  }
};
export const local = storageFactory(() => localStorage);

export const appendToken = () => {
  // let token = JSON.parse(local.getItem("individualInfo")).ssoUrlToken
  let loginToken = local.getItem("loginToken");
  return "?token=" + loginToken;
};

export const ageFromDOB = (dob) => {
  let ageDiffrence = new Date() - new Date(dob);
  let ageCurrent = new Date(ageDiffrence);
  let currentUserAge = Math.abs(ageCurrent.getUTCFullYear() - 1970);
  return currentUserAge;
};

export const yearValidation = (value) => {
  return "Years";
};

export const isNumber = (value) => {
  if (isNaN(value)) {
    value = 0;
  }
};

export const numToValue = (value) => {
  let checkVal = value < 0 ? "-" : "";
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Crs";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " L";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + " K";
  } else if (val >= 100) {
    val = (val / 100).toFixed(2) + "";
  } else if (isNaN(val)) {
    val = 0;
  } else {
    val = val + "";
  }
  return `${checkVal}${val}`;
};

export const convertRetiralScore = (value) => {
  if (isNaN(value) || value <= 0) {
    return `0%`;
  }
  if (value >= 500) {
    return `500%+`;
  } else {
    return `${Math.round(value)}%`;
  }
};
export const setStatusPendingInVerification = async () => {
  const individualInfo = localStorage.getItem("individualInfo");
  let jsonBody = {
    individualInfo: JSON.parse(individualInfo),
  };
  await api(process.env.REACT_APP_CMS_API)
    .post("horizontal/updatePending", jsonBody)
    .then((res) => {
      console.log("success done");
    })
    .catch((err) => {
      console.log(err, " error in pending Api");
    });
};
const fetchDataOnInterval = async (jsonBody, reduxFnObj = null) => {
  await api(process.env.REACT_APP_CMS_API)
    .post("horizontal/check-application-status", jsonBody)
    .then((res) => {
      let dta =
        res && res.data && res.data.responseBody && res.data.responseBody.data
          ? res.data.responseBody.data
          : null;
      if (dta && !dta.link) {
        if (window.showModalDetail) {
          window.showModalDetail((obj) => {
            return {
              ...obj,
              loadingMsg: null,
              showConfirmation: false,
              show: false,
            };
          });
        }
        localStorage.removeItem("intervalDta");
        if (window.setInvestmentInterval)
          clearInterval(window.setInvestmentInterval);
        if (reduxFnObj && reduxFnObj.executeDispatchFn && !dta.error) {
          let investment = reduxFnObj.data.calc_calculated?.investedInstruments;
          if (dta.viewed_gratuity === "1")
            investment["Gratuity"] = {
              current: dta.old_gratuity,
              pending_label: null,
            };
          if (dta.viewed_superannuation === "1")
            investment["Superannuation Fund"] = {
              current: dta.old_superannuation,
              pending_label: null,
            };
          if (dta.viewed_leave_encashment === "1")
            investment["Leave Encashment"] = {
              current: dta.old_leave_encashment,
              pending_label: null,
            };
          reduxFnObj.executeDispatchFn({
            type: ADDCALCULATIONDATASUCCESS,
            payLoad: reduxFnObj.data,
          });
        }
        if (window.showToasterMsg)
          window.showToasterMsg((obj) => {
            return { ...obj, message: dta.alertMsg, showAlert: true };
          });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
function setCache(type, jsonBody, reduxFnObj) {
  localStorage.setItem(
    "intervalDta",
    JSON.stringify({
      investmentType: type,
      investmentDta: jsonBody,
      reduxDta: reduxFnObj.data,
    })
  );
}
export const checkStatusOnInterval = (type, jsonBody, reduxFnObj) => {
  if (window.setInvestmentInterval) clearInterval(window.setInvestmentInterval);
  if (!window.investmentType) window.investmentType = [];
  if (window.investmentType && window.investmentType.indexOf(type) === -1)
    window.investmentType.push(type);
  setCache(type, jsonBody, reduxFnObj);
  window.setInvestmentInterval = setInterval(() => {
    jsonBody.allMessage = window.investmentType;
    fetchDataOnInterval(jsonBody, reduxFnObj);
  }, 20000);
};

export const waitngForBtnClick = (fnCall) => {
  return new Promise((resolve) => {
    if (fnCall)
      fnCall((obj) => {
        return {
          ...obj,
          loadingMsg: "Fetching data please wait....",
          width: "50%",
          height: "auto",
          showConfirmation: true,
          show: true,
          actionBtn: resolve,
        };
      });
  });
};
export const redirectToUrl = async (
  link,
  delay,
  type = null,
  fnCall = null,
  loaderFn = null,
  reduxFnObj = null
) => {
  let linkBreaked = link.split(".");
  const individualInfo = localStorage.getItem("individualInfo");
  const authToken = localStorage.getItem("loginToken");
  if (
    linkBreaked.includes("life99") ||
    linkBreaked.includes("https://life99")
  ) {
    setTimeout(() => {
      let encryptedParams = EncryptData({
        target: link,
        token: authToken,
        individualInfo: JSON.parse(individualInfo),
        mvpLogin: true,
      });
      const url = `${link}?token=${encryptedParams}`;
      window.location.href = url;
    }, delay);
  } else {
    if (
      type &&
      (type.replace(/\s/gi, "").toLowerCase() === "gratuity" ||
        type.replace(/\s/gi, "").toLowerCase() === "superannuationfund" ||
        type.replace(/\s/gi, "").toLowerCase() === "superannuationfunds" ||
        type.replace(/\s/gi, "").toLowerCase() === "saf" ||
        type.replace(/\s/gi, "").toLowerCase() === "le" ||
        type.replace(/\s/gi, "").toLowerCase() === "leaveencashment")
    ) {
      let jsonBody = {
        target: link,
        token: authToken,
        individualInfo: JSON.parse(individualInfo),
        type: type,
        mvpLogin: true,
      };
      if (fnCall) {
        let allow = await waitngForBtnClick(fnCall);
        if (!allow) return;
        if (
          !window.getSetModalValue?.selectedVal ||
          !window.getSetModalValue?.selectedInput ||
          window.getSetModalValue?.selectedVal === "" ||
          window.getSetModalValue?.selectedInput === "" ||
          window.getSetModalValue?.selectedVal === "undefined" ||
          window.getSetModalValue?.selectedInput === "undefined"
        )
          return;
        jsonBody.companyIdUserS = window.getSetModalValue.selectedVal;
        jsonBody.companyNameUserS = window.getSetModalValue.selectedInput;
        if (loaderFn) loaderFn(true);
      }
      await api(process.env.REACT_APP_CMS_API)
        .post(link, jsonBody)
        .then((res) => {
          let dta =
            res &&
            res.data &&
            res.data.responseBody &&
            res.data.responseBody.data
              ? res.data.responseBody.data
              : null;
          if (loaderFn) loaderFn(false);
          if (reduxFnObj && reduxFnObj.executeDispatchFn) {
            let investment =
              reduxFnObj.data.calc_calculated?.investedInstruments;
            if (
              type.replace(/\s/gi, "").toLowerCase() === "gratuity" &&
              dta.old_gratuity
            ) {
              investment["Gratuity"] = {
                current: dta.old_gratuity,
                pending_label: dta.ispending === "1" ? true : false,
              };
            }
            if (
              (type.replace(/\s/gi, "").toLowerCase() ===
                "superannuationfund" ||
                type.replace(/\s/gi, "").toLowerCase() ===
                  "superannuationfunds" ||
                type.replace(/\s/gi, "").toLowerCase() === "saf") &&
              dta.old_superannuation
            )
              investment["Superannuation Fund"] = {
                current: dta.old_superannuation,
                pending_label: dta.ispending === "1" ? true : false,
              };
            if (
              (type.replace(/\s/gi, "").toLowerCase() === "leaveencashment" ||
                type.replace(/\s/gi, "").toLowerCase() === "le") &&
              dta.old_leave_encashment
            )
              investment["Leave Encashment"] = {
                current: dta.old_leave_encashment,
                pending_label: dta.ispending === "1" ? true : false,
              };
            reduxFnObj.executeDispatchFn({
              type: ADDCALCULATIONDATASUCCESS,
              payLoad: reduxFnObj.data,
            });
          }
          setCache(type, jsonBody, reduxFnObj);
          // if (dta && dta.link) {
          //   checkStatusOnInterval(type, jsonBody, reduxFnObj);
          // }
          if (dta && dta.link && fnCall && !dta.ispending) {
            fnCall((obj) => {
              return {
                ...obj,
                selectedInput: "",
                selectedVal: "",
                loadingMsg: null,
                showConfirmation: false,
                url: "https://" + dta.link,
                show: true,
              };
            });
          } else {
            if (fnCall) {
              fnCall((obj) => {
                return {
                  ...obj,
                  selectedInput: "",
                  selectedVal: "",
                  url: null,
                  loadingMsg: null,
                  showConfirmation: false,
                  show: false,
                  redirectToInvestment: true,
                };
              });
            }
          }
          // return res?.data
        })
        .catch((err) => {
          if (loaderFn) loaderFn(false);
          console.error(err, " Error in redirect url Utils");
        });
    } else {
      if (loaderFn) loaderFn(false);
      window.location.href = link;
    }
  }
};

export const redirectLink = (link) => {
  const url = window.open(link);
  url.opener = null;
};

export const GoogleAnalystic = (action, category, event, url) => {
  window.dataLayer.push({
    action: action,
    category: category,
    event: event,
    url: url,
  });
};

export const mapCheck = (value) => {
  if (Array.isArray(value) && value?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const handleSanchayPlusRedirection = async () => {
  let token = local.getItem("loginToken");
  let individuelInfo = JSON.parse(local.getItem("individualInfo"));
  let individualId = individuelInfo.individual_id;
  let SANCHAY_PLUS_BUY = process.env.REACT_APP_SANCHAY_PLUS_URL;
  let sanchayData;
  api(baseURL, token)
    .get("api-mdm/sanchay-plus/get-sanchay-policy-status")
    .then((res) => {
      const { status } = res?.data?.data;
      if (status) {
        window.location.href = res?.data?.data?.redirectUrl;
      } else {
        window.location.href = `${SANCHAY_PLUS_BUY}&partnerUserId=${individualId}`;
      }
    });

  return sanchayData;
};
