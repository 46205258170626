import { lazy } from "react";

const UnlinkPopup = lazy(() => import("../views/Popus/UnlinkPopup"));

const AnnuityMV = lazy(() => import("../views/Annuity/AnnuityMV"));
const WebAnnuity = lazy(() => import("../views/Annuity/WebAnnuityPC"));

const Toast = lazy(() => import("../views/Notification/Toast"));

const ChatWithUsMV = lazy(() =>
  import("../views/common/ChatButton/ChatWithUsMV")
);

const Example = lazy(() => import("../views/PDFs/Example"));

const Portfolio = lazy(() => import("../views/Portfolio/Index"));
// const PortfolioPC = lazy(() => import("../views/Portfolio/Portfolioweb"));

const RetiralScorePC = lazy(() =>
  import("../views/Retiral Score/RetiralScorePC")
);

const RetiralScoreMV = lazy(() =>
  import("../views/Retiral Score/RetiralScoreMV")
);

const OfferModelMV = lazy(() =>
  import("../views/dashboard/WellBeingNew/OfferComponents/OfferModelMV")
);
const Dashboard = lazy(() => import("../components/PreLogin/Dashboard"));
const SignUp = lazy(() => import("../components/Auth/SignUp/Index"));
const Login = lazy(() => import("../components/Auth/Login/Index"));
const ResetPassword = lazy(() =>
  import("../components/Auth/ResetPassword/Index")
);
const CareGiverSignUp = lazy(() =>
  import("../components/Auth/CareGiver/SignUp/Index")
);
const Subscription = lazy(() =>
  import("../components/Auth/Subscription/Index")
);
const ThankYou = lazy(() =>
  import("../components/Auth/Subscription/Thankyou/Index")
);

export const localRoutes = [
  {
    path: "/popups",
    component: UnlinkPopup,
    exact: true,
  },
  {
    path: "/annuity-mobile",
    component: AnnuityMV,
    exact: true,
  },
  {
    path: "/WebAnnuity",
    component: WebAnnuity,
    exact: true,
  },
  {
    path: "/Toast",
    component: Toast,
    exact: true,
  },
  {
    path: "/chat-with-us",
    component: ChatWithUsMV,
    exact: true,
  },
  {
    path: "/pdf-example",
    component: Example,
    exact: true,
  },
  {
    path: "/portal/employee/portfolio",
    component: Portfolio,
    exact: true,
  },
  {
    path: "/portal/employee/portfolio",
    component: Portfolio,
    exact: true,
  },
  {
    path: "/retiral_score-web",
    component: RetiralScorePC,
    exact: true,
  },
  {
    path: "/retiral-score-mobile",
    component: RetiralScoreMV,
    exact: true,
  },
  {
    path: "/offermodel-mobile",
    component: OfferModelMV,
    exact: true,
  },
  {
    path: "/pre-login",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
  },
  {
    path: "/caregiver/signup",
    component: CareGiverSignUp,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/subscribe",
    component: Subscription,
    exact: true,
  },
  {
    path: "/subscribe/thanks",
    component: ThankYou,
    exact: true,
  },
];
