/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./Investcard.scss";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
const Context = React.createContext();
const createAuto = createFilterOptions();
const AutoSuggestModal = React.memo(() => {
  const [props, optionDta, defaultForSearch, open_, getList, axiosFetchList] =
    React.useContext(Context);
  const [open, setOpen] = useState(false);
  const [loader, autoLoader] = React.useState(true);
  window.autoLoader = autoLoader;
  const [searchTerm, setSearchTerm] = useState(null);
  React.useEffect(() => {
    if (!loader) {
      return undefined;
    }
    if (open_.isActive) {
      axiosFetchList(defaultForSearch);
    }

    return () => {
      open_.isActive = false;
    };
  }, [loader]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptionDta([]);
  //   }
  // }, [open]);
  React.useEffect(async () => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      if (searchTerm && defaultForSearch) {
        defaultForSearch.searchName = searchTerm;
        getList(defaultForSearch);
      }
      // Send Axios request here
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <Autocomplete
      // freeSolo
      disablePortal
      id="fetch-company-list"
      disableClearable
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={
        props.selectedInput && props.selectedVal
          ? props.selectedInput + " [" + props.selectedVal + "]"
          : null
      }
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          window.showModalDetail((obj) => {
            return { ...obj, selectedInput: newValue, selectedVal: newValue };
          });
          // setValue({
          //   title: newValue,
          // });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          window.showModalDetail((obj) => {
            return {
              ...obj,
              selectedInput: newValue?.inputValue,
              selectedVal: newValue?.company_id,
            };
          });
        } else {
          window.showModalDetail((obj) => {
            return {
              ...obj,
              selectedInput: newValue?.company_name || newValue,
              selectedVal: newValue?.company_id || newValue,
            };
          });
        }
      }}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        // axios search
        setSearchTerm(inputValue);
        const filtered = createAuto(options, params);

        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.company_name
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            company_name: `Add "${inputValue}"`,
            company_id: inputValue,
            id: inputValue,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.company_name;
      }}
      options={optionDta}
      loading={loader}
      renderOption={(props, option) => (
        <li {...props} key={option.id ? option.id : option.company_id}>
          {option.company_name + " [" + option.company_id + "]"}
        </li>
      )}
      selectOnFocus
      clearOnBlur
      openOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select corporate"
          InputProps={{
            ...params.InputProps,
            type: "search",
            endAdornment: (
              <React.Fragment>
                {loader ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={params?.inputProps?.value.trim() === "" ? true : false}
          color={params?.inputProps?.value.trim() === "" ? "error" : "info"}
          helperText={
            params?.inputProps?.value.trim() === ""
              ? "Please select or add corporate"
              : ""
          }
        />
      )}
    />
  );
});
const PopUpModal = (props) => {
  //let top10Copr = [];
  const defaultForSearch = {
    pageCt: 0,
    searchName: "",
    limit: 200,
    totalSize: 0,
  };
  if (window.autoSugLoader === undefined)
    window.autoSugLoader = {
      isActive: true,
    };
  const [optionDta, setOptionDta] = useState([]);
  let baseUrl = process.env.REACT_APP_CMS_API;
  const axiosFetchList = () => {
    axios
      .post(`${baseUrl}/horizontal/search-company`, defaultForSearch)
      .then((res) => {
        if (res?.data?.responseBody?.data)
          setOptionDta(res.data.responseBody.data);
        window.autoLoader(false);
        // return Promise.resolve();
      })
      .catch((err) => {
        window.autoLoader(false);
        console.warn(err);
        // return Promise.resolve();
      });
  };
  const getList = (defaultForSearch, isAwait = false) => {
    window.autoLoader(true);
    if (window.getListTimeOut) clearTimeout(window.getListTimeOut);
    window.getListTimeOut = setTimeout(() => {
      let dta = defaultForSearch;
      axiosFetchList(dta);
    }, 700);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.width ? props.width : "84%",
    height: props.height ? props.height : "90%",
    bgcolor: "background.paper",
    border: "1px solid #b1adad",
    boxShadow: 24,
    padding: "8px",
  };
  let text =
    props && props.buttonProperty && props.buttonProperty.confirmationMessage
      ? props.buttonProperty.confirmationMessage
      : "";
  let cancelBtn =
    props && props.buttonProperty && props.buttonProperty.cancelBtnLabel
      ? props.buttonProperty.cancelBtnLabel
      : null;
  let allowBtn =
    props && props.buttonProperty && props.buttonProperty.allowBtnLabel
      ? props.buttonProperty.allowBtnLabel
      : null;
  let actionBtn =
    props && props.actionBtn && typeof props.actionBtn === "function"
      ? props.actionBtn
      : null;
  return (
    <Modal
      sx={{ zIndex: "1400" }}
      open={props.show}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          props.handle_Close(props.showConfirmation);
          if (props.showConfirmation) actionBtn(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle} className="card alertModal">
        <IconButton
          className="popUp-cancel-btn"
          onClick={() => {
            window.getListTimeOut = undefined;
            window.autoLoader = undefined;
            window.autoSugLoader = undefined;
            props.handle_Close(props.showConfirmation, false);
            if (props.showConfirmation) actionBtn(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          className={!props.showConfirmation ? "card__body" : "main-popup-Div"}
        >
          {!props.showConfirmation ? (
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            <iframe src={props.url} width={"100%"} height={"100%"}></iframe>
          ) : (
            <>
              <div className="modal-text-body autoSuggest">
                <Context.Provider
                  value={[
                    props,
                    optionDta,
                    defaultForSearch,
                    window.autoSugLoader,
                    getList,
                    axiosFetchList,
                  ]}
                >
                  <AutoSuggestModal />
                </Context.Provider>
              </div>
              <div className="modal-text-break"> {text}</div>
              <div className="modal-action-body">
                <div className="margin-right-5">
                  <Button
                    className="cancelAlertBtn"
                    variant="outlined"
                    exact={true}
                    onClick={() => {
                      window.getListTimeOut = undefined;
                      window.autoLoader = undefined;
                      window.autoSugLoader = undefined;
                      if (actionBtn) {
                        actionBtn(false);
                        props.handle_Close(props.showConfirmation, false);
                      } else return;
                    }}
                  >
                    {cancelBtn}
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    className="comfirmationAlertBtn"
                    exact={true}
                    onClick={() => {
                      window.getListTimeOut = undefined;
                      window.autoLoader = undefined;
                      window.autoSugLoader = undefined;
                      if (
                        actionBtn &&
                        window.getSetModalValue.selectedVal &&
                        window.getSetModalValue.selectedVal.trim() !== ""
                      ) {
                        actionBtn(true);
                        props.handle_Close(props.showConfirmation, true);
                      } else return;
                    }}
                  >
                    {allowBtn}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default PopUpModal;
