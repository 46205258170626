/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { landingPageRouters } from "../views/landing/LandingRouter";
import { dashboardPageRouters } from "../views/dashboard/dashboardRouters";
import { myAccountPageRouters } from "../views/MyAccount/myAccountRouter";
import { emptyNavPageRouter } from "../views/EmptyNavComponents/emptyNavRouter";
import { loginAndRegisterPageRouters } from "../views/Register/RegisteRouter";
import { loginPageRouters } from "../views/Login/LoginRouter";
import { calculatorPageRouter } from "../views/calculator/calculatorRouter";
import { GoalImagesRouter } from "../views/dashboard/GraphImages/GoalImagesRouter";
import { errorPageRouters } from "../views/common/Error/ErrorRoutes";
import { localRoutes } from "./localRoutes";
import { retiralPdfRoute } from "../views/RetiralPdf/retiralPdfRoute";
import { ssoRoutes } from "../views/SSO/ssoRouting";
import {
  checkStatusOnInterval,
  setStatusPendingInVerification,
} from "../utils/Utils";
import { corpRegisterRouting } from "../views/CorpRegister/corpRegisterRouting";
import Toast from "../views/MyAccount/common/modal/Toast";
import PopUpModal from "../views/dashboard/Invest/popUpModal";

import LoaderWrapper from "./loaderWrapper";
import ScrollToTop from "./scrollToTop";
import PrivateRoute from "./PrivateRoute";
import { ADDCALCULATIONDATASUCCESS } from "../Redux/actions/types";
import Loading from "../views/common/Loader/Loading";
function setIntervalDta(intervalDta, dispatch) {
  if (
    intervalDta &&
    intervalDta["investmentType"] &&
    intervalDta["investmentDta"]
  ) {
    let reduxObj = {
      executeDispatchFn: dispatch,
      data: intervalDta["reduxDta"],
    };
    checkStatusOnInterval(
      intervalDta["investmentType"],
      intervalDta["investmentDta"],
      reduxObj
    );
  }
}

function updateRedux(intervalDta, dispatch) {
  if (intervalDta?.investmentType) {
    let type = intervalDta?.investmentType;
    let investment =
      intervalDta["reduxDta"].calc_calculated?.investedInstruments;
    if (type.replace(/\s/gi, "").toLowerCase() === "gratuity") {
      investment["Gratuity"] = {
        current: 0,
        pending_label: true,
      };
    }
    if (
      type.replace(/\s/gi, "").toLowerCase() === "superannuationfund" ||
      type.replace(/\s/gi, "").toLowerCase() === "superannuationfunds" ||
      type.replace(/\s/gi, "").toLowerCase() === "saf"
    )
      investment["Superannuation Fund"] = {
        current: 0,
        pending_label: true,
      };
    if (
      type.replace(/\s/gi, "").toLowerCase() === "leaveencashment" ||
      type.replace(/\s/gi, "").toLowerCase() === "le"
    )
      investment["Leave Encashment"] = {
        current: 0,
        pending_label: true,
      };
    dispatch({
      type: ADDCALCULATIONDATASUCCESS,
      payLoad: intervalDta["reduxDta"],
    });
  }
}
function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
}
const CommonComponent = () => {
  const dispatch = useDispatch();
  // const isReferralFlag = useSelector(
  //   (state) => state?.dashboradReducer?.referralFlag
  // );
  const [alertdetails_, setAlertDetails_] = useState({
    error: false,
    message: "",
    showAlert: false,
  });
  const defaultVal = {
    show: false,
    state: false,
    isActive: true,
    selectedInput: "",
    selectedVal: "",
    url: "",
    loadingMsg: null,
    redirectToInvestment: false,
    buttonProperty: {
      confirmationMessage: `We will use your HRMS login credentials to fetch your payroll information from third party. Note:- Your updated details will be fetched in the second week of next month if you attempt more than once in the same month`,
      cancelBtnLabel: "Cancel",
      allowBtnLabel: "Allow",
    },
    showConfirmation: false,
    width: "84%",
    height: "95%",
  };
  const [modalOpen, setModalOpen] = useState(defaultVal);

  // useEffect(() => {}, [setModalOpen]);
  const handleClose = (isActionPgClick = false, loadMsg) => {
    window.autoSugLoader = undefined;
    if (!isActionPgClick) {
      setModalOpen(defaultVal);
    } else {
      setModalOpen((obj) => {
        return {
          ...obj,
          ...defaultVal,
          loadingMsg: loadMsg ? obj.loadingMsg : false,
          selectedInput: obj.selectedInput,
          selectedVal: obj.selectedVal,
        };
      });
    }
  };

  window.showToasterMsg = setAlertDetails_;
  window.showModalDetail = setModalOpen;
  window.getSetModalValue = modalOpen;
  let intervalDta = JSON.parse(localStorage.getItem("intervalDta"));
  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e?.data?.status === "INCOME_VERIFICATION_SUCCESS") {
        intervalDta = JSON.parse(localStorage.getItem("intervalDta"));
        updateRedux(intervalDta, dispatch);
        setStatusPendingInVerification();
        setIntervalDta(intervalDta, dispatch);
        if (window.showModalDetail) {
          window.showModalDetail((obj) => {
            return { ...obj, showConfirmation: false, show: false };
          });
        }
      }
    });

    return () => {
      window.addEventListener("message", (e) => {
        if (e?.data?.status === "INCOME_VERIFICATION_SUCCESS") {
          intervalDta = JSON.parse(localStorage.getItem("intervalDta"));
          updateRedux(intervalDta, dispatch);
          setStatusPendingInVerification();
          if (window.showModalDetail) {
            window.showModalDetail((obj) => {
              return { ...obj, showConfirmation: false, show: false };
            });
          }
        }
      });
    };
  }, []);

  return (
    <>
      <Toast
        isOpen={alertdetails_.showAlert}
        toggle={() =>
          setAlertDetails_((obj) => {
            return { ...obj, showAlert: false };
          })
        }
        message={alertdetails_?.message}
        error={alertdetails_?.error}
      />

      <PopUpModal
        buttonProperty={modalOpen.buttonProperty}
        actionBtn={modalOpen.actionBtn}
        showConfirmation={modalOpen.showConfirmation}
        show={modalOpen.show}
        handle_Close={handleClose}
        url={modalOpen.url}
        height={modalOpen.height}
        selectedVal={modalOpen.selectedVal}
        selectedInput={modalOpen.selectedInput}
        width={modalOpen.width}
      />
    </>
  );
};

export default function AppRouter({ ...props }) {
  const location = window.location.pathname;
  const pathname = location?.split("/");

  if (pathname.length && pathname[1] === "register") {
    window.location.href = process?.env?.REACT_APP_LIFE99 + location;
  }
  const dispatch = useDispatch();
  let intervalDta = JSON.parse(localStorage.getItem("intervalDta"));
  setIntervalDta(intervalDta, dispatch);
  const error404 = React.lazy(() => import("../views/common/Error/Error404"));

  let allPublicRoutes = [
    ...localRoutes,
    ...landingPageRouters,
    ...loginAndRegisterPageRouters,
    ...loginPageRouters,
    ...GoalImagesRouter,
    ...errorPageRouters,
    ...corpRegisterRouting,
  ];

  let allPrivateRoutes = [
    ...calculatorPageRouter,
    ...myAccountPageRouters,
    ...emptyNavPageRouter,
    ...dashboardPageRouters,
    ...retiralPdfRoute,
  ];

  return (
    <div className="app-router">
      <LoaderWrapper>
        <div className="router-layout">
          <Router>
            <ScrollToTop>
              <Switch>
                {allPublicRoutes.map((value, index) => {
                  return (
                    <Route
                      exact={value.exact}
                      path={value.path}
                      component={WaitingComponent(value.component)}
                      key={index}
                    />
                  );
                })}

                {allPrivateRoutes.map((value, index) => {
                  return (
                    <PrivateRoute
                      exact={value.exact}
                      path={value.path}
                      component={WaitingComponent(value.component)}
                      key={index}
                    />
                  );
                })}

                <Route
                  path={ssoRoutes.path}
                  component={WaitingComponent(ssoRoutes.component)}
                  key={1}
                />

                <Route>{WaitingComponent(error404)}</Route>
              </Switch>
            </ScrollToTop>
          </Router>
        </div>
        <CommonComponent />
      </LoaderWrapper>
    </div>
  );
}
