import React from "react";
// import { Link } from "react-router-dom";
// import success from "../../assets/images/Cards/success .svg";
// import error from "../../assets/images/Cards/error.svg";
// import info from "../../assets/images/Cards/info.svg";

import "./Toast.scss";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";

function Toast({ isOpen, toggle, message, error, duration = 5500 }) {
  //Toast handling

  return (
    <>
      <div className="Toast">
        <Snackbar
          open={isOpen}
          autoHideDuration={duration}
          onClose={toggle}
          message={
            <Box paddingRight={4}>
              <div className="seconddiv container ">
                <div className="successpopup">
                  <div className="success">
                    {!error && (
                      <>
                        <CheckCircleIcon className="successIcon" />{" "}
                        <h4>Success Message</h4>
                      </>
                    )}
                    {error && (
                      <>
                        <CancelOutlinedIcon
                          sx={{ color: "red" }}
                          className="errorIcon"
                        />
                        <h4>Error Message</h4>
                      </>
                    )}
                  </div>
                </div>
                <span className="timeto align-items-center d-inline-flex flex-shrink-0">
                  {message}
                </span>
              </div>
            </Box>
          }
          action={
            <IconButton onClick={toggle}>
              <CloseIcon sx={{ color: "#fff" }} />
            </IconButton>
          }
        />
      </div>
    </>
  );
}
export default Toast;
